<template>

<!-- 校园管理 学生管理  新增页面 -->
  <div class="teacherManageAdd-container">
    <div class="teacjer-info">
      <el-form label-position="right" label-width="120px" ref="studentFromRef" :model="studentFrom" :rules="studentFromSref">
        <el-form-item v-if="getClassId" label="年级班级" prop="classId">
          <el-select v-model="studentFrom.classId" filterable placeholder="请选择">
            <el-option
              v-for="(item, index) in getNewGradeClassList"
              :key="index"
              :label="item.label"
              :value="item.value">
              <span style="float: left">{{ item.label }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.grade }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="!getClassId" label="年级班级" prop="classId">
          <el-select v-model="studentFrom.classId" placeholder="请选择年级班级">
          <el-option
            v-for="item in classList"
            :key="item.classId"
            :label="item.className"
            :value="item.classId">
          </el-option>
        </el-select>
        </el-form-item>
        <el-form-item label="学生名称" prop="name">
          <el-input maxlength="11" v-model="studentFrom.name"></el-input>
        </el-form-item>
         <el-form-item label="联系方式" prop="phone">
          <el-input maxlength="11" v-model="studentFrom.phone"></el-input>
        </el-form-item>
         <el-form-item label="性别" prop="sex">
          <el-select v-model="studentFrom.sex" placeholder="请选择性别">
            <el-option
              v-for="item in ['男', '女']"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="出生日期" prop="birthday">
          <el-date-picker
            v-model="studentFrom.birthday"
            type="date"
            :picker-options="pickerOptions"
            placeholder="选择学生出生日期">
          </el-date-picker>
        </el-form-item>
         <el-form-item label="身份证" prop="idCard">
          <el-input maxlength="18" v-model="studentFrom.idCard"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
          maxlength="200"
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            resize="none"
            v-model="studentFrom.remark">
          </el-input>
        </el-form-item>
         <!-- <el-form-item label="左眼视力" prop="leftVisionData">
          <el-input maxlength="4"  v-model="studentFrom.leftVisionData"></el-input>
        </el-form-item>
         <el-form-item label="右眼视力" prop="rightVisionData">
          <el-input maxlength="4"  v-model="studentFrom.rightVisionData"></el-input>
        </el-form-item> -->
        <el-form-item label="左裸眼视力" prop="leftVisionData">
          <el-input maxlength="4" v-model="studentFrom.leftVisionData"></el-input>
        </el-form-item>
         <el-form-item label="右裸眼视力" prop="rightVisionData">
          <el-input maxlength="4" v-model="studentFrom.rightVisionData"></el-input>
        </el-form-item>
        <el-form-item label="左戴镜视力" prop="wearLeftVisionData">
          <el-input maxlength="4" v-model="studentFrom.wearLeftVisionData"></el-input>
        </el-form-item>
         <el-form-item label="右戴镜视力" prop="wearRightVisionData">
          <el-input maxlength="4" v-model="studentFrom.wearRightVisionData"></el-input>
        </el-form-item>
         <el-form-item label="屈光左眼球镜DS" prop="refractiveLeftDs">
          <el-input maxlength="5" v-model="studentFrom.refractiveLeftDs"></el-input>
        </el-form-item>
         <el-form-item label="屈光右眼球镜DS" prop="refractiveRightDs">
          <el-input maxlength="5" v-model="studentFrom.refractiveRightDs"></el-input>
        </el-form-item>
        <el-form-item label="屈光左眼柱镜DC" prop="refractiveLeftDc">
          <el-input maxlength="5" v-model="studentFrom.refractiveLeftDc"></el-input>
        </el-form-item>
         <el-form-item label="屈光右眼柱镜DC" prop="refractiveRightDc">
          <el-input maxlength="5" v-model="studentFrom.refractiveRightDc"></el-input>
        </el-form-item>
        <el-form-item label="左眼轴位" prop="refractiveLeftAxis">
          <el-input maxlength="3" v-model.number="studentFrom.refractiveLeftAxis"></el-input>
        </el-form-item>
         <el-form-item label="右眼轴位" prop="refractiveRightAxis">
          <el-input maxlength="3" v-model.number="studentFrom.refractiveRightAxis"></el-input>
        </el-form-item>
        <FormButtonGroup @saveData="saveData"></FormButtonGroup>
      </el-form>
    </div>
  </div>
</template>
<script>
import storage from '@/globals/storage/index.js'
import studentService from '@/globals/service/management/student.js'
import GradeClassService from '@/globals/service/management/GradeClass.js'

export default {
  data () {
    return {
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > new Date().getTime()
        }
      },
      classList: [],
      getClassId: false,
      getNewGradeClassList: [],
      studentFromSref: {
        classId: [
          { required: true, message: '请选择学生班级', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入学生名称', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入联系号码', trigger: 'blur' },
          { required: true, max: 11, message: '请输入11位手机号码', trigger: 'blur' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '目前只支持中国大陆的手机号码',
            trigger: 'blur'
          }
        ],
        birthday: [
          { required: true, message: '请输入学生生日', trigger: 'blur' }
        ],
        sex: [
          { required: true, message: '请选择孩子性别', trigger: 'blur' }
        ],
        // idCard: [
        //   { pattern: /^\d{15}|\d{}18$/, message: '请输入正确身份证', trigger: 'blur' }
        // ],
        leftVisionData: [
          { pattern: /^[0-9]+(.[0-9]{1})?$/, message: '请输入0.0格式', trigger: 'blur' }
        ],
        rightVisionData: [
          { pattern: /^[0-9]+(.[0-9]{1})?$/, message: '请输入0.0格式', trigger: 'blur' }
        ],
        wearLeftVisionData: [
          { pattern: /^[0-9]+(.[0-9]{1})?$/, message: '请输入0.0格式', trigger: 'blur' }
        ],
        wearRightVisionData: [
          { pattern: /^[0-9]+(.[0-9]{1})?$/, message: '请输入0.0格式', trigger: 'blur' }
        ],
        refractiveLeftDs: [
          { pattern: /^[+-]?\d{1}\.\d{2}$/, message: '请输入0.00/-0.00格式', trigger: 'blur' }
        ],
        refractiveRightDs: [
          { pattern: /^[+-]?\d{1}\.\d{2}$/, message: '请输入0.00/-0.00格式', trigger: 'blur' }
        ],
        refractiveLeftDc: [
          { pattern: /^[+-]?\d{1}\.\d{2}$/, message: '请输入0.00/-0.00格式', trigger: 'blur' }
        ],
        refractiveRightDc: [
          { pattern: /^[+-]?\d{1}\.\d{2}$/, message: '请输入0.00/-0.00格式', trigger: 'blur' }
        ]
      },
      studentFrom: {
        classId: '',
        // 手机
        phone: '',
        // 名称
        name: '',
        // 生日
        birthday: '',
        // 性别
        sex: '',
        // 身份证
        idCard: '',
        // 备注
        remark: '',
        // 左裸眼视力
        leftVisionData: '',
        // 右裸眼视力
        rightVisionData: '',
        // 左戴镜视力
        wearLeftVisionData: '',
        // 右戴镜视力
        wearRightVisionData: '',
        // 屈光左眼球镜DS
        refractiveLeftDs: '',
        // 屈光右眼球镜DS
        refractiveRightDs: '',
        // 屈光左眼柱镜DC
        refractiveLeftDc: '',
        // 屈光右眼柱镜DC
        refractiveRightDc: '',
        // 左轴位
        refractiveLeftAxis: '',
        // 右轴位
        refractiveRightAxis: '',
        // 筛查计划
        longList: []
      }
    }
  },
  created () {
    const teacherClassId = storage.get('teacherClassId')
    this.classId = teacherClassId
    if (!teacherClassId) {
      this.getClassId = true
      this.getClassGrade()
    } else {
      this.getTeacherClassGrade()
    }
  },
  methods: {
    getTeacherClassGrade () {
      studentService.getClassId().then(res => {
        this.classList = res.data
        // this.getClassId = teacherClassId
        // this.getData()
        const teacherClassId = storage.get('teacherClassId')
        this.studentFrom.classId = teacherClassId
      })
    },
    getClassGrade () {
      const schoolId = storage.get('schoolId')
      GradeClassService.getNewGradeClass({ schoolId, pageSize: 500, startPage: 1 }).then(res => {
        this.getNewGradeClassList = res.data.classVoList.map(item => {
          return {
            id: item.id,
            value: item.id,
            label: item.isClass,
            grade: item.grade
          }
        })
      })
    },
    saveData () {
      this.$refs.studentFromRef.validate(valid => {
        if (valid) {
          // const teacherClassId = storage.get('teacherClassId')
          const data = this.studentFrom
          // console.log('data2', data)
          data.birthday = (new Date(data.birthday)).getTime()
          studentService.addStudent(data).then(res => {
            this.$message({
              message: '添加成功',
              type: 'success'
            })
            this.$router.push({ name: 'managementStudentManagement' })
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.teacherManageAdd-container{
  background-color: #fff;
  min-height: 100%;
  width: 100%;
  padding: 80px 40px 20px 61px;
  .teacjer-info{
    max-width: 500px;
    min-width: 400px;
    color: #333;
    .el-col-2{
      text-align: center;
    }
    .el-select{
      width: 100%;
    }
  }
  .button-section{
    .el-button{
      margin-left: 20px;
    }
  }
}
</style>
